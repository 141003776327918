import { AssetUsageType, HousingStatus } from "@prisma/client";
import { getValuesFromRepeatableAnswer } from "src/utils/answers";
import { z } from "zod";
import { schemaAge } from "~/modules/common/schemas";
import {
  assetTypeOptions,
  booleanOptions,
} from "~/modules/common/static/common/formSelectOption";
import { COUNTRIES_FR_AND_DOMTOM } from "~/modules/common/utils/countries";
import {
  additionalIncomeOptions,
  assetCompanyTypeOptions,
  assetOwnershipTypeOptions,
  assetShareholdersTypeOptions,
  employeeTypeOptions,
  jobTypeOptions,
  maritalStatusOptions,
  marriageTypeOptions,
} from "../../../common/static/standard/formSelectOption";
import { QuestionInlineSlug, QuestionSimpleSlug } from "../../enums";
import { type Question, type QuestionOption } from "../../interfaces";
import { schemaSurface } from "../../schemas";
import { getAssetUsageTypeOption } from "../../utils/questionOptions";
import { CommonQuestions } from "../common/questions";

export const questionsObo: Question[] = [
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddress,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Sur quel bien souhaitez vous faire la vente à soi-même ?"
        : "Où se situe votre bien immobilier ?";
    },
    type: "address",
    countryList: COUNTRIES_FR_AND_DOMTOM,
    placeholder: "",
    label: "Adresse du bien :",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetValue,
    question: "Quelle est sa valeur ?",
    type: "currency-positive",
    placeholder: "600 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetSurface,
    question: "Quelle est sa surface ?",
    type: "number",
    placeholder: "",
    unit: "m²",
    customSchema: schemaSurface,
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data > 500) {
        return "S’agit-il bien de la surface de votre bien ?";
      }
      return null;
    },
  },
  CommonQuestions[QuestionSimpleSlug.AssetLoans],
  CommonQuestions[QuestionSimpleSlug.HousingLoans],
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetType,
    question: "Quel est la typologie de ce bien ?",
    type: "btn",
    placeholder: "",
    options: assetTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetUsageType,
    question: "Quel est l'usage de ce bien ?",
    type: "btn",
    placeholder: "",
    options: (answers) => {
      const options: QuestionOption[] = [];
      const assetUsageTypes = answers.filter(
        (answer) => answer.questionSlug === QuestionSimpleSlug.AssetUsageType,
      );

      if (
        !assetUsageTypes.find(
          (answer) => answer.value === AssetUsageType.main_residence,
        )
      ) {
        options.push(getAssetUsageTypeOption(AssetUsageType.main_residence));
      }
      options.push(getAssetUsageTypeOption(AssetUsageType.secondary_residence));
      options.push(getAssetUsageTypeOption(AssetUsageType.rental));

      return options;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetRentAnnualAmount,
    question: "Quel revenu locatif en retirez-vous annuellement ?",
    type: "currency-positive",
    placeholder: "15 000",
    unit: "€",
    label: "Montant",
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data < 1000) {
        return "S’agit-il du revenu que vous en retirez annuellement ?";
      }

      return null;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetOwnershipType,
    question: "Comment est-il détenu ?",
    type: "btn",
    placeholder: "",
    options: assetOwnershipTypeOptions,
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetJointOwnerInfo,
    question: "Vos indivisaires",
    description: "Les personnes avec qui vous détenez ce bien",
    itemName: "Indivisaire",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetJointOwnerFirstname,
        question: "Prénom",
        type: "text",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetJointOwnerLastname,
        question: "Nom",
        type: "text",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetJointOwnerSharePercentage,
        question: "Parts",
        type: "number",
        placeholder: "",
        unit: "%",
      },
    ],
    initialValues: (answers) => {
      const values = getValuesFromRepeatableAnswer(answers, {
        slug: QuestionSimpleSlug.AssetJointOwnerInfo,
        categoryIndex: 1,
      });
      return values;
    },
    refineSchema: {
      condition: (data) => {
        const sumSharePercentage = data.reduce((acc, item) => {
          const parsedValue = z
            .number()
            .safeParse(item[QuestionInlineSlug.AssetJointOwnerSharePercentage]);
          if (!parsedValue.success) {
            return acc;
          }
          return acc + parsedValue.data;
        }, 0);
        return sumSharePercentage <= 100;
      },
      params: {
        message:
          "La somme des parts de vos indivisaires doit être inférieur ou égale à 100",
        path: ["sumOfPercentage"],
      },
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetCompanyType,
    question: "Type de société ?",
    type: "btn",
    placeholder: "",
    options: assetCompanyTypeOptions,
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetCompanyShareholderInfo,
    question: "Vos actionnaires",
    description: "",
    itemName: "Actionnaire",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderFirstname,
        question: "Prénom",
        type: "text",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderLastname,
        question: "Nom",
        type: "text",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderType,
        question: "Type",
        type: "select",
        placeholder: "",
        options: assetShareholdersTypeOptions,
      },
      {
        slug: QuestionInlineSlug.AssetCompanyShareholderSharePercentage,
        question: "Parts",
        type: "number",
        placeholder: "",
        unit: "%",
      },
    ],
    refineSchema: {
      condition: (data) => {
        const sumSharePercentage = data.reduce((acc, item) => {
          const parsedValue = z
            .number()
            .safeParse(
              item[QuestionInlineSlug.AssetCompanyShareholderSharePercentage],
            );
          if (!parsedValue.success) {
            return acc;
          }
          return acc + parsedValue.data;
        }, 0);
        return sumSharePercentage <= 100;
      },
      params: {
        message:
          "La somme des parts de vos actionnaires doit être inférieur ou égale à 100",
        path: ["sumOfPercentage"],
      },
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AssetAddNew,
    question: "Avez-vous d’autres biens immobiliers?",
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerAge,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est votre âge ?"
        : "Quel est son âge ?";
    },
    type: "number",
    placeholder: "",
    unit: "ans",
    customSchema: schemaAge,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerChildrenCount,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Combien d'enfants avez-vous ?"
        : "Combien d'enfants a-t-il (elle) ?";
    },
    type: "number",
    placeholder: "",
    unit: "",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerAddress,
    question: "Où habitez-vous ?",
    type: "address",
    placeholder: "",
    unit: "",
    countryList: COUNTRIES_FR_AND_DOMTOM,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerMaritalStatus,
    question: "Quelle est votre situation de famille ?",
    type: "btn",
    placeholder: "",
    options: maritalStatusOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerMarriageType,
    question: "Quel est votre régime matrimonial ?",
    type: "btn",
    placeholder: "",
    options: marriageTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerJobType,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est votre statut professionnel ?"
        : "Quel est son statut professionnel ?";
    },
    type: "btn",
    placeholder: "",
    options: jobTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerJobEmployeeType,
    question: "Type de contrat salarié ?",
    type: "btn",
    placeholder: "",
    options: employeeTypeOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerHasEnoughAnnualIncome,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Avez-vous deux bilans ?"
        : "A-t-il (elle) deux bilans ?";
    },
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.CustomerJobTrial,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Êtes-vous en période d’essai ?"
        : "Est-il (elle) en période d’essai ?";
    },
    type: "btn",
    placeholder: "",
    options: booleanOptions,
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AnnualIncome,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est votre revenu brut annuel ?"
        : "Quel est son revenu brut annuel ?";
    },
    type: "currency-positive",
    placeholder: "150 000",
    unit: "€",
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data < 12000) {
        return "S'agit-il bien de votre revenu brut annuel ?";
      }
      return null;
    },
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.LastYearAnnualIncome,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est votre revenu net annuel ?"
        : "Quel est son revenu net annuel ?";
    },
    type: "currency-positive",
    placeholder: "150 000",
    unit: "€",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.AnnualPensionIncome,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Quel est le montant annuel de votre pension de retraite ?"
        : "Quel est le montant annuel de sa pension de retraite ?";
    },
    type: "currency-positive",
    placeholder: "0",
    unit: "€",
    label: "Montant",
    getWarning: (value) => {
      const parsedValue = z.number().safeParse(value);
      if (!parsedValue.success) {
        console.error("Cannot parse value for getWarning", value);
        return null;
      }
      if (parsedValue.data < 10000) {
        return "S'agit-il bien de votre pension retaite annuelle ?";
      }
      return null;
    },
  },
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AdditionalIncomeInfo,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Avez-vous d’autres revenus ?"
        : "A-t-il (elle) d’autres revenus ?";
    },
    description:
      "Autres que ceux déjà saisis: revenu foncier, produit de placement, pension, variable.",
    itemName: "Revenu additionnel",
    questionsInline: [
      {
        slug: QuestionInlineSlug.AdditionalIncomeType,
        question: "Type",
        type: "select",
        placeholder: "",
        options: additionalIncomeOptions,
      },
      {
        slug: QuestionInlineSlug.AdditionalAnnualIncome,
        question: "Montant annuel",
        type: "currency-positive",
        placeholder: "500",
        unit: "€",
      },
    ],
    infoBox:
      "Si vous avez déjà renseigné des revenus locatifs, ne pas les saisir à nouveau ici",
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.HousingStatus,
    question: "Aujourd'hui vous êtes :",
    type: "btn",
    placeholder: "",
    options: [
      {
        label: "Propriétaire",
        value: HousingStatus.landlord,
      },
      {
        label: "Locataire",
        value: HousingStatus.tenant,
      },
    ],
  },
  {
    questionType: "simple",
    slug: QuestionSimpleSlug.HousingRentMonthlyAmount,
    question: "Quel est le montant du loyer mensuel ?",
    type: "currency-positive",
    placeholder: "0,00",
    unit: "€",
    label: "Montant",
  },
  CommonQuestions[QuestionSimpleSlug.AdditionalLoans],
  {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.InvestmentsInfo,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Avez-vous des placements financiers ?"
        : "A-t-il (elle) des placements financiers?";
    },
    description: "",
    itemName: "Placement",
    questionsInline: [
      {
        slug: QuestionInlineSlug.InvestmentsType,
        question: "Type",
        type: "text",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.InvestmentsAmount,
        question: "Montant",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
      },
    ],
  },
];
