import { LoanType } from "@prisma/client";
import { loanTypeOptions } from "~/modules/common/static/common/formSelectOption";
import { assetLoanGuaranteeOptions } from "~/modules/common/static/standard/formSelectOption";
import {
  LoanPurpose,
  QuestionInlineSlug,
  QuestionSimpleSlug,
} from "../../enums";
import { type Question } from "../../interfaces";
import {
  customSchemaAdditionalLoans,
  customSchemaAssetLoans,
  customSchemaHousingLoans,
} from "./customSchemas";

type CommonQuestions = Extract<
  QuestionSimpleSlug,
  | QuestionSimpleSlug.AssetLoans
  | QuestionSimpleSlug.AdditionalLoans
  | QuestionSimpleSlug.HousingLoans
>;

export const CommonQuestions: Record<CommonQuestions, Question> = {
  [QuestionSimpleSlug.AssetLoans]: {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AssetLoans,
    question: "Avez-vous des crédits sur le bien?",
    description: "",
    itemName: "Crédit",
    customSchema: customSchemaAssetLoans,
    questionsInline: [
      {
        slug: QuestionInlineSlug.AssetLoanLoanType,
        question: "Type",
        type: "radio",
        placeholder: "",
        options: loanTypeOptions,
        className: "col-span-1 lg:col-span-2",
      },
      {
        slug: QuestionInlineSlug.AssetLoanMonthlyPaymentAmount,
        question: "Mensualité",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AssetLoanLoanType] ===
          LoanType.depreciable,
      },
      {
        slug: QuestionInlineSlug.AssetLoanAmount,
        question: "Montant total",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AssetLoanLoanType] ===
          LoanType.in_fine,
      },
      {
        slug: QuestionInlineSlug.AssetLoanEndDate,
        question: "Date de fin",
        type: "date",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.AssetLoanRate,
        question: "Taux",
        // TODO: Change to percentage type, but data saver must be updated accordingly
        type: "number",
        placeholder: "1.5",
        unit: "%",
      },
      {
        slug: QuestionInlineSlug.AssetLoanGuarantee,
        question: "Type de garantie",
        type: "select",
        placeholder: "",
        options: assetLoanGuaranteeOptions,
      },
    ],
  },
  [QuestionSimpleSlug.AdditionalLoans]: {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.AdditionalLoans,
    question: (navigation) => {
      return navigation.categoryIteration === 1
        ? "Avez-vous des crédits additionnels ?"
        : "A-t-il (elle) des crédits additionnels ?";
    },
    description: "",
    itemName: "Crédit",
    customSchema: customSchemaAdditionalLoans,
    questionsInline: [
      {
        slug: QuestionInlineSlug.AdditionalLoanPurpose,
        question: "Type",
        type: "select",
        placeholder: "",
        className: "col-span-1 lg:col-span-2",
        options: [
          { label: "Immobilier", value: LoanPurpose.RealEstate },
          { label: "Consommation", value: LoanPurpose.Consumer },
        ],
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanType,
        question: "Type",
        type: "radio",
        placeholder: "",
        options: loanTypeOptions,
        className: "col-span-1 lg:col-span-2",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AdditionalLoanPurpose] ===
          LoanPurpose.RealEstate,
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanMonthlyPaymentAmount,
        question: "Mensualité",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AdditionalLoanPurpose] ===
            LoanPurpose.Consumer ||
          (formState?.[QuestionInlineSlug.AdditionalLoanPurpose] ===
            LoanPurpose.RealEstate &&
            formState?.[QuestionInlineSlug.AdditionalLoanType] ===
              LoanType.depreciable),
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanAmount,
        question: "Montant total",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AdditionalLoanPurpose] ===
            LoanPurpose.RealEstate &&
          formState?.[QuestionInlineSlug.AdditionalLoanType] ===
            LoanType.in_fine,
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanEndDate,
        question: "Date de fin",
        type: "date",
        placeholder: "",
        unit: "",
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanRate,
        question: "Taux",
        // TODO: Change to percentage type, but data saver must be updated accordingly
        type: "number",
        placeholder: "1.5",
        unit: "%",
      },
      {
        slug: QuestionInlineSlug.AdditionalLoanGuarantee,
        question: "Type de garantie",
        type: "select",
        placeholder: "",
        options: assetLoanGuaranteeOptions,
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.AdditionalLoanPurpose] ===
          LoanPurpose.RealEstate,
      },
    ],
    infoBox:
      "Si vous avez déjà renseigné des crédits immobiliers, ne pas les saisir à nouveau. Ajoutez ici vos éventuels crédits consommation ou LOA.",
  },
  [QuestionSimpleSlug.HousingLoans]: {
    questionType: "repeatable",
    slug: QuestionSimpleSlug.HousingLoans,
    question: "Avez-vous des crédits sur le bien?",
    description: "",
    itemName: "Crédit",
    customSchema: customSchemaHousingLoans,
    questionsInline: [
      {
        slug: QuestionInlineSlug.HousingLoanLoanType,
        question: "Type",
        type: "radio",
        placeholder: "",
        options: loanTypeOptions,
        className: "col-span-1 lg:col-span-2",
      },
      {
        slug: QuestionInlineSlug.HousingLoanMonthlyPaymentAmount,
        question: "Mensualité",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.HousingLoanLoanType] ===
          LoanType.depreciable,
      },
      {
        slug: QuestionInlineSlug.HousingLoanAmount,
        question: "Montant total",
        type: "currency-positive",
        placeholder: "0,00",
        unit: "€",
        displayCondition: (formState) =>
          formState?.[QuestionInlineSlug.HousingLoanLoanType] ===
          LoanType.in_fine,
      },
      {
        slug: QuestionInlineSlug.HousingLoanEndDate,
        question: "Date de fin",
        type: "date",
        placeholder: "",
      },
      {
        slug: QuestionInlineSlug.HousingLoanRate,
        question: "Taux",
        // TODO: Change to percentage type, but data saver must be updated accordingly
        type: "number",
        placeholder: "1.5",
        unit: "%",
      },
      {
        slug: QuestionInlineSlug.HousingLoanGuarantee,
        question: "Type de garantie",
        type: "select",
        placeholder: "",
        options: assetLoanGuaranteeOptions,
      },
    ],
  },
};
