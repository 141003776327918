export const MONTHS_IN_1_YEAR = 12;

export const MONTHS_IN_10_YEARS = 120;

export const MONTHS_IN_20_YEARS = 240;

export const MONTHS_IN_25_YEARS = 300;

export const HYPPO_DEFAULT_LOAN_RATE = 0.05;

export const MAX_INT4 = 2147483647; // largest value that can be stored in a 4-byte signed integer variable
export const MIN_INT4 = -2147483648; // smallest value that can be stored in a 4-byte signed integer variable
