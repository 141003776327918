export const formatNumberToLocaleCurrency = (
  value: number,
  decimals = 0,
): string => {
  const valueFormatted = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value);
  return valueFormatted;
};

export const formatLocaleCurrencyToNumber = (value: string): number => {
  const valueWithoutSpaces = value.replace(/\s/g, "");

  return +valueWithoutSpaces;
};

// Luhn Algorithm function for Siret validation
// algorithm explanation link : https://portal.hardis-group.com/doccenter/pages/viewpage.action?pageId=120357227
// refined implementation based on : https://gist.github.com/brunobuddy/35d41b4cc6faf8ec636255ae3664b7dc
export const isValidSiret = (siret: number): boolean => {
  const siretString = siret.toString();

  if (siretString.length !== 14 || !/^\d+$/.test(siretString)) return false;

  let sum = 0;
  for (let i = 0; i < 14; i++) {
    const char = siretString[i];
    if (char !== undefined) {
      let n = parseInt(char, 10);

      if (i % 2 === 0) {
        n *= 2;
        if (n > 9) n -= 9;
      }

      sum += n;
    }
  }

  return sum % 10 === 0;
};

export const sumBy = <T>(array: T[], iteratee: (item: T) => number): number => {
  return array.reduce((acc, item) => acc + iteratee(item), 0);
};
