import {
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
  type ElementType,
  type FC,
  type HTMLAttributeAnchorTarget,
  type ReactNode,
} from "react";
import { Text, type TextSize } from "src/modules/common/components/atoms/Text";
import { cn } from "~/modules/common/components/utils/styles";

export type ButtonPrimaryVariant =
  | "primary"
  | "secondary"
  | "light"
  | "tertiary"
  | "quaternary";

export interface IButtonPrimaryProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "prefix"
  > {
  leftComponent?: ReactNode;
  rightComponent?: ReactNode;
  textClass?: string;
  variant?: ButtonPrimaryVariant;
  size?: "xs" | "small" | "large";
  textSize?: TextSize;
  as?: ElementType;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
}

export const ButtonPrimary: FC<IButtonPrimaryProps> = ({
  className,
  children,
  variant = "primary",
  textSize,
  as = "button",
  disabled,
  size = "large",
  leftComponent,
  rightComponent,
  href,
  textClass,
  ...rest
}) => {
  const Component = href ? "a" : as;

  return (
    <Component
      className={cn(
        "flex cursor-pointer items-center rounded-full px-4 py-2.5 hover:opacity-80",
        {
          "bg-new-primary-1 text-new-primary-4 transition-colors duration-75":
            !disabled && variant === "primary",
          "bg-new-green-1 text-new-dark-0 transition-colors duration-75":
            !disabled && variant === "secondary",
          "bg-new-primary-4 text-new-dark-green-3 transition-colors duration-75":
            !disabled && variant === "light",
          "border border-new-light-0 bg-new-light-4 px-3 py-2.5 text-new-dark-0":
            !disabled && variant === "tertiary",
          "border-none bg-new-light-2 transition-colors duration-75":
            !disabled && variant === "quaternary",
          "pointer-events-none cursor-not-allowed bg-new-light-2 text-new-dark-green-3":
            disabled,
        },
        size === "small" && "px-[10px]",
        size === "xs" && "px-3 py-1.5",
        className,
      )}
      disabled={disabled}
      href={href}
      {...rest}
    >
      {leftComponent}
      {children && (
        <Text
          size={textSize ?? (size === "small" ? "tiny" : "lead")}
          className={cn(
            "grow px-[5px] py-[3px] text-center text-inherit",
            size === "small" && "px-[3px]",
            size === "xs" && "py-0",
            textClass,
          )}
        >
          {children}
        </Text>
      )}
      {rightComponent}
    </Component>
  );
};
