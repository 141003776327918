import { z } from "zod";

// public Pappers route
export const schemaPappersCompanyName = z.object({
  resultats_siret: z.array(
    z.object({
      nom_entreprise: z.string().nullish(),
      siege: z.object({ siret: z.string() }),
    }),
  ),
});

// paid Pappers route
export const pappersHeadquartersSchema = z.object({
  siret: z.string(),
  siret_formate: z.string(),
  diffusion_partielle: z.boolean().nullable(),
  adresse_ligne_1: z.string().nullable(),
  adresse_ligne_2: z.string().nullable(),
  code_postal: z.string(),
  ville: z.string(),
  pays: z.string(),
});

const pappersCollectiveAgreementSchema = z.object({
  nom: z.string().nullish(),
  idcc: z.number().nullish(),
});

const pappersSanctionSchema = z.object({
  description: z.string().nullish(),
  autorite: z.string().nullish(),
  en_cours: z.boolean().nullish(),
  date_debut: z.string().nullish(),
  date_fin: z.string().nullish(),
});

const pappersBeneficiarySchema = z.object({
  type: z.string().nullish(),
  nom: z.string().nullish(),
  prenom: z.string().nullish(),
  pourcentage_parts: z.number().nullish(),
  pourcentage_votes: z.number().nullish(),
  sanctions_en_cours: z.boolean().nullish(),
  sanctions: z.array(pappersSanctionSchema),
});

const pappersRepresentativeSchema = z.object({
  qualites: z.array(z.string()).nullish(),
  nom: z.string().nullish(),
  prenom: z.string().nullish(),
  age: z.number().nullish(),
  sanctions_en_cours: z.boolean().nullish(),
  sanctions: z.array(pappersSanctionSchema).nullish(),
});

const financeSchema = z.object({
  annee: z.number().nullish(),
  chiffre_affaires: z.number().nullish(),
  resultat_exploitation: z.number().nullish(),
  capacite_remboursement: z.number().nullish(),
  liquidite_generale: z.number().nullish(),
  dettes_financieres: z.number().nullish(),
  fonds_propres: z.number().nullish(),
  resultat: z.number().nullish(),
});

const pappersCollectiveProcedureSchema = z.object({
  type: z.string().nullish(),
  date_debut: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .nullish(),
  date_fin: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/)
    .nullish(),
});

// Define the main schema
export const pappersDataSchema = z.object({
  siren: z.string(),
  siren_formate: z.string(),
  nom_entreprise: z.string().nullable(),
  personne_morale: z.boolean(),
  nom: z.string().nullable(),
  prenom: z.string().nullable(),
  siege: pappersHeadquartersSchema,
  code_naf: z.string(),
  libelle_code_naf: z.string(),
  domaine_activite: z.string().nullish(),
  objet_social: z.string().nullish(),
  conventions_collectives: z.array(pappersCollectiveAgreementSchema),
  date_creation: z.string(),
  entreprise_cessee: z.boolean(),
  forme_juridique: z.string(),
  effectif: z.string().nullish(),
  annee_effectif: z.number().nullish(),
  capital: z.number().nullable(),
  date_cloture_exercice: z.string().nullish(),
  numero_rcs: z.string().nullable(),
  numero_tva_intracommunautaire: z.string(),
  finances: z.array(financeSchema).optional(),
  representants: z.array(pappersRepresentativeSchema),
  beneficiaires_effectifs: z.array(pappersBeneficiarySchema),
  procedure_collective_en_cours: z.boolean(),
  procedures_collectives: z.array(pappersCollectiveProcedureSchema),
  forme_exercice: z.string().nullish(),
});
