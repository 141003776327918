"use client"; // Error components must be a Client Component
import { type FC, type ReactNode } from "react";
import { Text } from "~/modules/common/components/atoms/Text";
import { IconHelpCircle } from "../atoms/icons/IconHelpCircle";

interface ErrorPageProps {
  children?: ReactNode;
}

export const ErrorPage: FC<ErrorPageProps> = ({ children }) => {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center gap-6">
      <IconHelpCircle
        width={128}
        height={128}
        className="text-green-1 opacity-90"
      />
      <Text size="h2" className="text-green-1">
        500
      </Text>
      <Text>{"Une erreur est survenue lors du chargement de la page"}</Text>
      {children}
    </div>
  );
};
